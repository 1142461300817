<template>
  <en-drawer title="定金缴纳" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :loading="form.loading" :ref="setRef('form')" label-position="left" label-width="80">
      <en-form-item label="金额" prop="amount">
        <en-input-number v-model="form.data.amount" :min="0" :precision="2" class="w-full"></en-input-number>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const formDataInit = (): EnocloudSettlementDefinitions['ReceivableDto'] => {
  return { amount: 0 } as any
}

export default factory({
  props: {
    modelValue: Boolean,
    customer: Object as PropType<Record<string, any> | undefined>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.data = formDataInit()
          this.form.data.payer = Object.assign({}, this.customer) as EnocloudSettlementDefinitions['CustomerDto']
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        rules: {
          amount: [{ required: true, message: '请输入定金金额' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/settlement/receivable/deposit',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.emit('update:model-value', false)
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
